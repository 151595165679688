<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">修改教师</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>姓名</b></label>
            <el-input type="text" v-model="model.NAME" disabled />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label><b>手机</b></label>
            <el-input type="text" v-model="model.MOBILE" disabled />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label><b>部门</b></label>
            <el-input type="text" v-model="model.DEPT_NAME" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label><b>角色</b></label>
            <el-select v-model="model.ROLE" placeholder="无" clearable style="display: block" @change="roleChange">
              <el-option v-for="it in roles" :label="it.DESCRIPTION" :value="it.ROLE_NAME" :key="it.ROLE_NAME" />
            </el-select>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        sta: {
          show: false,
          loading: false,
        },
        model: {},
        roles: [],
        page: 0
      };
    },
    methods: {
      init(model, page) {
        this.sta = { show: true, loading: false };
        this.page = page;
        if (model == undefined || model == null) {
          this.model = {};
        } else {
          this.model = JSON.parse(JSON.stringify(model));
        }

        if (this.roles.length == 0) this.getRoles();
      },
      getRoles() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/School/ACL/RoleApi/GetList",
          completed: function (its) {
            self.roles = its;
          },
        });
      },
      roleChange(e) {
        this.model.ROLE_NAME = this.roles.find(
          (item) => item.ROLE_NAME === e
        ).DESCRIPTION;
      },
      submit() {
        let self = this;

        this.whale.remote.getResult({
          url: "/api/School/ORG/TeacherApi/Update",
          data: self.model,
          finally() {
            self.sta.loading = false;
          },
          completed: function () {
            self.sta.show = false;
            self.$emit("on-saved", self.page);
          },
        });
      },
    },
  };
</script>