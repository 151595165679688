<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-world"> </i> 通用管理 | 教师列表</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row" v-if="USER.ROLE == 'ADMIN'">
          <div class="col-md-12">
            <el-button type="warning" @click="setRole()">黏贴权限</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px">
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px">
                <el-button slot="append" icon="el-icon-search" @click="filter(0)"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage" @selection-change="selectionChange">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="姓名" width="150" align="center">
                <template slot-scope="scope">
                  {{ scope.row.NAME }}
                  <span v-if="scope.row.ROLE == 'ADMIN'" style="color: red">【管理员】</span>
                </template>
              </el-table-column>
              <el-table-column prop="MOBILE" label="手机" width="120" align="center" />
              <el-table-column label="微信" width="50" align="center">
                <template slot-scope="scope">
                  <i class="el-icon-check" v-if="scope.row.OPEN_ID" />
                </template>
              </el-table-column>
              <el-table-column label="操作" width="280">
                <template slot-scope="scope" v-if="USER.ROLE == 'ADMIN'">
                  <el-button type="text" icon="el-icon-monitor" @click="editMenuP(scope.row)">权限P</el-button>
                  <el-button type="text" icon="el-icon-monitor" @click="copyMenu(scope.row,1)">复制授权P</el-button>
                  <el-button type="text" icon="el-icon-edit-outline" @click="editRole(scope.row)">修改角色</el-button>
                  <el-button type="text" icon="el-icon-mobile-phone" @click="editMenuM(scope.row)">权限M</el-button>
                  <el-button type="text" icon="el-icon-mobile-phone" @click="copyMenu(scope.row,2)">复制授权M</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="ROLE_NAMES" label="PC权限"></el-table-column>
              <el-table-column prop="MENU_M_NAMES" label="手机权限"></el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit_MP ref="edit_mp" @on-saved="filter" />
      <Edit_R ref="edit_r" @on-saved="filter" />
      <Edit_MM ref="edit_mm" @on-saved="filter" />
    </div>
  </div>
</template>

<script>
  import Wgrid from "@/components/wgrid";
  import Edit_MP from "./edit_menu_p";
  import Edit_R from "./edit_role";
  import Edit_MM from "./edit_menu_m";
  export default {
    name: "index",
    components: { Wgrid, Edit_MP, Edit_R, Edit_MM },
    data() {
      return {
        grid: {
          sea: {
            PAGE_SIZE: 12,
            PAGE_INDEX: 0,
            ROLE: "",
            KEY: "",
          },
          ls: [],
          total: 0,
          loading: false,
        },
        roles: [],
        selectTeachers: [],
        USER: JSON.parse(localStorage.getItem("userInfo")),
        copyMenuIds: [],
        type: 1
      };
    },
    created() {
      this.getList();
      this.getRoles();
    },
    methods: {
      filter(page) {
        this.grid.sea.PAGE_INDEX = page || 0;
        this.grid.ls = [];
        this.getList();
      },
      loadPage(pi) {
        this.grid.sea.PAGE_INDEX = pi - 1;
        this.getList();
      },
      getList() {
        let self = this;
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/ORG/TeacherApi/GetList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;
          },
        });
      },
      getRoles() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/School/ACL/RoleApi/GetList",
          completed: function (its) {
            self.roles = its;
          },
        });
      },
      editMenuP(r) {
        this.$refs.edit_mp.init(r, this.grid.sea.PAGE_INDEX);
      },
      editMenuM(r) {
        this.$refs.edit_mm.init(r, this.grid.sea.PAGE_INDEX);
      },
      editRole(r) {
        this.$refs.edit_r.init(r, this.grid.sea.PAGE_INDEX);
      },
      copyMenu(val, type) {
        this.type = type;
        if (type == 1)
          this.copyMenuIds = val.MENUIDS;
        else
          this.copyMenuIds = val.MENU_M_IDS;
        console.log("copyMenuIds", this.copyMenuIds);
        this.$message.success("复制成功");
      },
      selectionChange(val) {
        console.log(val);
        this.selectTeachers = val;
      },
      setRole() {
        if (this.selectTeachers.length == 0)
          return false;
        var teacherIds = this.selectTeachers.map((c) => c.ID);

        console.log("teacherIds", teacherIds);
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/ORG/TeacherApi/CopyRoles",
          data: { ids: teacherIds, mids: self.copyMenuIds, type: self.type },
          completed: function (result) {
            console.log("result", result);
            self.$message.success("授权成功");
            self.getList();
          },
        });
      },
    },
  };
</script>

<style scoped>
  .el-button {
    margin-left: 0px;
    margin-right: 10px;
  }
</style>