<template>
    <el-dialog :visible.sync="sta.show" width="600px">
        <span slot="title">给教师 【{{tea.NAME}}】 分配权限(M) </span>
        <el-tree :data="menus" :props="defaultProps" accordion ref="tree" highlight-current show-checkbox node-key="ID"
            :default-expanded-keys="expandedkeys" :default-checked-keys="checkedkeys" check-strictly>
        </el-tree>
        <div slot="footer" v-loading="sta.loading">
            <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
            <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "hello",
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                model: { MID: '', TYPE: 2 },
                tea: {},
                expandedkeys: [],
                checkedkeys: [],
                menus: [],
                defaultProps: {
                    id: 'ID',
                    label: 'NAME',
                    disabled: 'IS_AUTH',
                    children: 'CHILDREN'
                },
                page: 0
            }
        },
        methods: {
            init(t, page) {
                this.sta = { show: true, loading: false }
                this.page = page;
                this.tea = JSON.parse((JSON.stringify(t)));
                this.model.GUID = t.GUID;
                if (this.menus.length == 0)
                    this.getTree();
                this.getRel();
            },
            getTree() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/ACL/MenuApi/GetMTree",
                    completed(its) {
                        self.menus = its.DATA
                        self.expandedkeys = [its.DATA[0].ID]
                    }
                })
            },
            getRel() {
                let self = this;
                if (self.menus.length == 0) {
                    setTimeout(self.getRel, 300)
                    return
                }

                self.$refs.tree.setCheckedKeys([]);
                this.whale.remote.getResult({
                    url: "/api/School/ORG/TeacherApi/GetRelMenu",
                    data: {
                        UID: self.tea.ID,
                        TYPE: self.model.TYPE
                    },
                    completed(its) {
                        self.checkedkeys = its.DATA
                    }
                })
            },
            submit() {
                let self = this;
                let keys = self.$refs.tree.getCheckedKeys()
                // console.log('keys', keys)

                self.model.MID = keys.join(',')
                self.sta.loading = true;
                self.whale.remote.getResult({
                    url: "/api/School/ORG/TeacherApi/UpdateMenu",
                    data: self.model,
                    finally() { self.sta.loading = false; },
                    completed: function () {
                        self.sta.show = false;
                        self.$emit("on-saved", self.page);
                    }
                })
            }
        }
    }
</script>